var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 60"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 30c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30C60 13.431 46.569 0 30 0 13.431 0 0 13.431 0 30Z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M15.187 29.453c8.053-3.508 13.423-5.822 16.11-6.94 7.672-3.19 9.266-3.744 10.305-3.763.228-.004.74.053 1.07.322.28.226.357.533.394.748.036.215.082.704.046 1.087-.416 4.368-2.215 14.968-3.13 19.86-.387 2.07-1.15 2.765-1.888 2.833-1.604.147-2.822-1.06-4.376-2.079-2.431-1.594-3.805-2.586-6.165-4.141-2.727-1.797-.96-2.785.595-4.4.407-.422 7.475-6.851 7.612-7.434.017-.073.033-.345-.129-.489-.161-.143-.4-.094-.572-.055-.243.055-4.127 2.623-11.652 7.702-1.102.757-2.1 1.125-2.995 1.106-.987-.021-2.884-.558-4.294-1.016-1.73-.562-3.105-.86-2.986-1.815.063-.497.748-1.006 2.055-1.526Z",
      "fill": "#fff"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }